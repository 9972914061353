import _ from 'underscore';
import Chart from 'chart.js';
import { View } from 'backbone.marionette';
import ChartState from '../../../student/achieves/chartState';

export default View.extend({
  template: false,

  ui: {
    canvas: 'canvas',
    panel : '.panel'
  },

  chartOptions: {
    layout: {
      padding: 5
    },
    scale: {
      ticks: {
        max     : 4,
        min     : 0,
        stepSize: 1
      }
    }
  },

  initialize() {
    this.chartState = new ChartState();
  },

  onRender() {
    const data = this.$el.data('chart-data');
    if (!data) return;

    this.changeMaxScale(data);
    _.each(data.datasets, (dataset, i) => {
      _.extend(dataset, this.chartState.getDatasetOptions(i));
    });

    this.renderChart(data);
  },

  // データに応じて目盛りの最大値を変更する
  changeMaxScale(data) {
    if (!data.max_scale) return;
    if (!this.chartOptions) return;
    if (!this.chartOptions.scale) return;
    if (!this.chartOptions.scale.ticks) return;

    this.chartOptions.scale.ticks.max = data.max_scale;
  },

  renderChart(data) {
    if (this.chart) this.chart.destroy();
    const ctx = this.ui.canvas.get(0).getContext('2d');

    this.chart = new Chart(ctx, {
      type   : 'radar',
      data   : data,
      options: _.extend(this.chartOptions, {
        legend: {
          position: 'bottom'
        },
        responsive                 : false,
        responsiveAnimationDuration: 0,
        animation                  : { duration: 0 }
      })
    });
  }
});
