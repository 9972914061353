import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import IndexMindsView from '../../views/manage/export/minds/index';
import IndexPlansView from '../../views/manage/export/plans/index';
import IndexGenericsView from '../../views/manage/export/generics/index';
import IndexBelongsView from '../../views/manage/export/belongs/index';
import IndexActivitiesView from '../../views/manage/export/activities/index';
import BtnWithLogsView from '../../views/manage/export/btn_with_logs';

const Controller = RestController.extend({
  indexAssessments() {
    (new BtnWithLogsView()).render();
  },

  indexSurveyAnswers() {
    (new BtnWithLogsView()).render();
  },

  indexMinds() {
    (new IndexMindsView()).render();
    (new BtnWithLogsView()).render();
  },

  indexPlans() {
    (new IndexPlansView()).render();
    (new BtnWithLogsView()).render();
  },

  indexGenerics() {
    (new IndexGenericsView()).render();
    (new BtnWithLogsView()).render();
  },

  indexLectureMemos() {
    (new BtnWithLogsView()).render();
  },

  indexLectureFeedbacks() {
    (new BtnWithLogsView()).render();
  },

  indexExams() {
    (new BtnWithLogsView()).render();
  },

  indexBelongs() {
    (new IndexBelongsView()).render();
    (new BtnWithLogsView()).render();
  },

  indexRecords() {
    (new BtnWithLogsView()).render();
  },

  indexLastLogins() {
    (new BtnWithLogsView()).render();
  },

  indexActivities() {
    (new IndexActivitiesView()).render();
    (new BtnWithLogsView()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/export/assessments'      : 'indexAssessments',
    ':tenant/manage/export/survey_answers'   : 'indexSurveyAnswers',
    ':tenant/manage/export/minds'            : 'indexMinds',
    ':tenant/manage/export/plans'            : 'indexPlans',
    ':tenant/manage/export/generics'         : 'indexGenerics',
    ':tenant/manage/export/lecture_memos'    : 'indexLectureMemos',
    ':tenant/manage/export/lecture_feedbacks': 'indexLectureFeedbacks',
    ':tenant/manage/export/exams'            : 'indexExams',
    ':tenant/manage/export/belongs'          : 'indexBelongs',
    ':tenant/manage/export/records'          : 'indexRecords',
    ':tenant/manage/export/last_logins'      : 'indexLastLogins',
    ':tenant/manage/export/activities'       : 'indexActivities'
  },

  initialize() {
    this.controller = new Controller();
  }
});
