export default {
  events: {
    'change [name=search_by]': 'onChangeSearchBy'
  },

  ui: {
    curriculumYearGroup : '.js-curriculum-year-group',
    curriculumYearSelect: '.js-curriculum-year-select',
    gradeGroup          : '.js-grade-group',
    gradeSelect         : '.js-grade-select',
    beingInput          : '.js-being-input'
  },

  onChangeSearchBy(e) {
    switch (this.$(e.currentTarget).val()) {
      case 'curriculum_year':
        this.ui.curriculumYearGroup.removeClass('hide');
        this.ui.curriculumYearSelect.prop('disabled', false);
        this.ui.gradeGroup.addClass('hide');
        this.ui.gradeSelect.prop('disabled', true);
        this.ui.beingInput.prop('disabled', true);
        break;
      case 'grade':
        this.ui.curriculumYearGroup.addClass('hide');
        this.ui.curriculumYearSelect.prop('disabled', true);
        this.ui.gradeGroup.removeClass('hide');
        this.ui.gradeSelect.prop('disabled', false);
        this.ui.beingInput.prop('disabled', false);
        break;
      default:
        break;
    }
  }
};
