import { View } from 'backbone.marionette';

// accepts_nested_attributes_forによる編集フィールド群のレンダリングが前提
// layouts/_rating_edit_field.html.slim を前提とする
export default View.extend({
  template: false,

  ui: {
    ratingField: 'input[type=number]',
    maxField   : 'input[type=hidden]'
  },

  onRender() {
    const max = this.ui.maxField.val();
    this.ui.ratingField.rating({
      size        : 'sm',
      max         : max,
      step        : 1,
      stars       : max,
      hoverEnabled: false,
      showCaption : false
    });
  }
});
