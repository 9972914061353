import _ from 'underscore';

// 累計・当期・累計平均・当期平均の4つのグラフの状態や設定を管理する
//   hidden: グラフのONOFF状態、年期切替時に維持するために保持する
//   colors: グラフの色、変動しないがhiddenと一緒に反映するオプション情報
export default class chartState {
  constructor() {
    this._condition = {};
    this._datasetHiddens = [false, false, true, true];
    this._datasetColors = [{
      backgroundColor     : 'rgba(151,187,205,0.2)',
      borderColor         : 'rgba(151,187,205,1)',
      pointBackgroundColor: 'rgba(151,187,205,1)',
      pointBorderColor    : '#fff'
    }, {
      backgroundColor     : 'rgba(219, 118, 91, 0.2)',
      borderColor         : 'rgba(219, 118, 91, 1)',
      pointBackgroundColor: 'rgba(219, 118, 91, 1)',
      pointBorderColor    : '#fff'
    }, {
      backgroundColor     : 'rgba(60, 179, 113, 0.2)',
      borderColor         : 'rgba(60, 179, 113, 1)',
      pointBackgroundColor: 'rgba(60, 179, 113, 1)',
      pointBorderColor    : '#fff'
    }, {
      backgroundColor     : 'rgba(243, 213, 26, 0.2)',
      borderColor         : 'rgba(243, 213, 26, 1)',
      pointBackgroundColor: 'rgba(243, 213, 26, 1)',
      pointBorderColor    : '#fff'
    }];
  }

  // 検索条件に平均フラグの利用状況を追加する
  getCondition() {
    return {
      avg_total: !this._datasetHiddens[2],
      avg_term : !this._datasetHiddens[3]
    };
  }

  getDatasetOptions(index) {
    const color = this._datasetColors[index];
    return _.extend({ hidden: this._datasetHiddens[index] }, color);
  }

  getDatasetHidden(index) {
    return this._datasetHiddens[index];
  }

  setDatasetHidden(index, flag) {
    this._datasetHiddens[index] = flag;
  }
}
