import _ from 'underscore';
import Chart from 'chart.js';
import { View } from 'backbone.marionette';

export default View.extend({
  template: false,

  ui: {
    canvas: 'canvas'
  },

  colors: [{
    backgroundColor     : 'rgba(151,187,205,0.2)',
    borderColor         : 'rgba(151,187,205,1)',
    pointBackgroundColor: 'rgba(151,187,205,1)',
    pointBorderColor    : '#fff'
  }, {
    backgroundColor     : 'rgba(219, 118, 91, 0.2)',
    borderColor         : 'rgba(219, 118, 91, 1)',
    pointBackgroundColor: 'rgba(219, 118, 91, 1)',
    pointBorderColor    : '#fff'
  }],

  onRender() {
    const data = this.$el.data('chart-data');
    if (!data) return;

    _.each(data.datasets, (dataset, i) => {
      _.extend(dataset, this.colors[i]);
    });

    this.changeMaxScale(data);
    this.createDummyAxis(data);
    this.renderChart(data);
  },

  // データに応じて目盛りの最大値を変更する
  changeMaxScale(data) {
    if (!data.max_scale) return;
    if (!this.chartOptions) return;
    if (!this.chartOptions.scale) return;
    if (!this.chartOptions.scale.ticks) return;

    this.chartOptions.scale.ticks.max = data.max_scale;
  },

  // 3軸未満だとレーダーチャートが図形にならないためダミー軸を作る
  createDummyAxis(data) {
    const length = data.labels.length;
    if (length === 0) return;
    if (length >= 3) return;


    // 空軸を不足分だけ追加する
    const LackNum = 3 - length;
    data.labels.push(...(new Array(LackNum)).fill(''));

    // 追加軸に対して０埋めする
    // ただし１軸の場合はそのままだと絶対に三角形にならなず、平均の値が裏に隠れてしまうので、擬似的な値で3角形にする
    const emptyAxisValue = (length === 1) ? this.chartOptions.scale.ticks.stepSize : 0;
    _.each(data.datasets, (dataset) => {
      dataset.data.push(...(new Array(LackNum)).fill(emptyAxisValue));
    });
  },

  renderChart(data) {
    if (this.chart) this.chart.destroy();
    const ctx = this.ui.canvas.get(0).getContext('2d');

    this.chart = new Chart(ctx, {
      type   : 'radar',
      data   : data,
      options: _.extend({}, this.chartOptions, {
        legend: {
          position: 'bottom'
        },
        responsive                 : false,
        responsiveAnimationDuration: 0,
        animation                  : { duration: 0 }
      })
    });
  }
});
