import { View } from 'backbone.marionette';
import d3 from 'd3';
import EditRubrics from '../../_layouts/edit_rubrics';
import EditRating  from '../../_layouts/edit_rating';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    ratingFields     : '.js-rating-field',
    rubricFields     : '.js-rubric-field',
    targetNumberIcons: '.js-asm-target-no-icon'
  },

  onRender() {
    this.renderRubrics();
    this.buildRating();
    this.paintIcons();
  },

  renderRubrics() {
    this.ui.rubricFields.each((_index, el) => {
      (new EditRubrics({ el: `#${el.id}` })).render();
    });
  },

  buildRating() {
    this.ui.ratingFields.each((_index, el) => {
      (new EditRating({ el: `#${el.id}` })).render();
    });
  },

  paintIcons() {
    const colors = d3.scale.category20().range();

    this.ui.targetNumberIcons.each((_, element) => {
      const index = Number(element.dataset.licenseIndex);
      const color = colors[index % colors.length];
      this.$el.find(element).css('color', color);
    });
  }
});
