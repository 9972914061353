import $ from 'jquery';
import _ from 'underscore';
import Chart from 'chart.js';
import { View } from 'backbone.marionette';
import ChartState from './chartState';

export default View.extend({
  template: false,

  ui: {
    canvas: 'canvas',
    panel : '.panel'
  },

  chartOptions: {
    layout: {
      padding: 5
    },
    scale: {
      ticks: {
        max     : 4,
        min     : 0,
        stepSize: 1
      }
    }
  },

  initialize() {
    this.chartState = new ChartState();
  },

  // マップIDを条件にチャートデータを読み込む
  load(data) {
    if (!this.$el.data('url')) return;

    const condition = _.extend({}, data,  this.chartState.getCondition());
    const $panel = this.ui.panel;

    $panel.block();
    $.get(this.$el.data('url'), condition)
     .done(_.bind(this.update, this))
     .always(() => {
       this._beforeCondition = condition;
       $panel.unblock();
     });
  },

  reload() {
    this.load(this._beforeCondition);
  },

  // 設定や状態を反映させつつチャートを更新
  update(data) {
    _.each(data.datasets, (dataset, i) => {
      _.extend(dataset, this.chartState.getDatasetOptions(i));
    });
    this.changeMaxScale(data);
    this.renderChart(data);
  },

  // データに応じて目盛りの最大値を変更する
  changeMaxScale(data) {
    if (!data.max_scale) return;
    if (!this.chartOptions) return;
    if (!this.chartOptions.scale) return;
    if (!this.chartOptions.scale.ticks) return;

    this.chartOptions.scale.ticks.max = data.max_scale;
  },

  renderChart(data) {
    if (this.chart) this.chart.destroy();
    const ctx = this.ui.canvas.get(0).getContext('2d');

    this.chart = new Chart(ctx, {
      type   : 'radar',
      data   : data,
      options: _.extend(this.chartOptions, {
        legend: {
          position: 'right',
          onClick : _.bind(this.onClickLegendItem, this)
        }
      })
    });
  },

  onClickLegendItem(e, item) {
    const index = item.datasetIndex;
    const isAvg = index >= 2;
    const beforeHidden = this.chartState.getDatasetHidden(index);
    const afterHidden = !item.hidden;  // 切替直前の値しか取れないので反転する

    // グラフのONOFF状態を保存する
    this.chartState.setDatasetHidden(index, afterHidden);

    // 本来のONOFF描画切替処理を行う
    Chart.defaults.global.legend.onClick.call(this.chart.legend, e, item);

    // 平均グラフをOFFからONにする場合はリロード（平均処理はON時のみ実行されるため）
    if (isAvg && beforeHidden && !afterHidden) this.reload();
  }
});
