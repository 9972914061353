import { View, CompositeView } from 'backbone.marionette';
import ratingsTemplate from '../../../templates/student/assessments/ratings.pug';
import ratingTemplate  from '../../../templates/student/assessments/rating.pug';

const RatingView = View.extend({
  tagName : 'tr',
  template: ratingTemplate,

  ui: {
    inputField: 'input',
    ratingTd  : 'td.asm-rating'
  },

  onRender() {
    const max = this.ui.inputField.attr('max');
    this.ui.inputField.rating({
      size        : 'xs',
      max         : max,
      step        : 1,
      stars       : max,
      readonly    : true,
      hoverEnabled: false,
      showCaption : false,
      showClear   : false
    });
  }
});

export default CompositeView.extend({
  template          : ratingsTemplate,
  childView         : RatingView,
  childViewContainer: 'tbody',

  serializeData() {
    return { hasRatyTeacher: this.hasRatyTeacher() };
  },

  hasRatyTeacher() {
    const test = (model) => { return model.get('raty_teacher') > 0; };
    return this.collection.any(test);
  }
});
