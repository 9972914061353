import { View } from 'backbone.marionette';
import ShowView from './show';
import AptitudeView from './aptitude';
import ProcessChart from './process_chart';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    selectpick: '.selectpicker',
    mapList   : 'select.js-map',
    yearList  : 'select.js-year'
  },

  events: {
    'change @ui.mapList' : 'changeMap',
    'change @ui.yearList': 'changeYear'
  },

  onRender() {
    this.ui.selectpick.selectpicker();
    this.showView = (new ShowView()).render();
    this.aptitudeView = (new AptitudeView()).render();
    this.processChart = (new ProcessChart()).render();

    if (this.ui.mapList.val() && this.ui.yearList.val()) {
      this.changeMap();
    }
  },

  // レーダーチャート: マップの切り替えではシート数が異なるためhtmlごと再描画する
  //      GPT達成度: レンダリング出力なので常に再描画
  changeMap() {
    const mapId = this.ui.mapList.val();
    const data = this._getSearchData();
    this.showView.load(mapId, data);
    this.aptitudeView.load(mapId, data);
    this.processChart.load(mapId, data);
  },

  // レーダーチャート: 時期の切り替えではデータの読み込みだけで良いのでリロード
  //      GPT達成度: レンダリング出力なので常に再描画
  changeYear() {
    const mapId = this.ui.mapList.val();
    const data = this._getSearchData();
    this.showView.reload(data);
    this.aptitudeView.load(mapId, data);
  },

  _getSearchData() {
    return { assessment_summary_id: this.ui.yearList.val() };
  }
});
