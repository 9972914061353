import { View } from 'backbone.marionette';
import d3 from 'd3';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';
import ShowRating from '../../_layouts/show_rating';

export default View.extend({
  el      : '#page',
  template: false,

  behaviors: [Behaviors.CommentPhrase],

  ui: {
    ratingFields     : '.js-rating-field',
    targetNumberIcons: '.js-asm-target-no-icon'
  },

  onRender() {
    this.buildRating();
    this.paintIcons();
    Utils.Opener.set('LrSkillAnswerDecoratorComments', this);
  },

  buildRating() {
    this.ui.ratingFields.each((_index, el) => {
      new ShowRating({ el: `#${el.id}` }).render();
    });
  },

  paintIcons() {
    const colors = d3.scale.category20().range();

    this.ui.targetNumberIcons.each((_, element) => {
      const index = Number(element.dataset.licenseIndex);
      const color = colors[index % colors.length];
      this.$el.find(element).css('color', color);
    });
  }
});
