import _ from 'underscore';
import { View } from 'backbone.marionette';
import GradesProcessTable from './grades/process_table';
import GradesRankingChart from './grades/ranking_chart';
import GenericResultChart from './generic/result_chart';
import GenericProcessChart from './generic/process_chart';
import AchieveResultChart from './achieves/result_chart';
import AchieveProcessChart from './achieves/process_chart';
import MindResultChart from './mind/result_chart';
import MindProcessChart from './mind/process_chart';
import ExamResultChart from './exam/result_chart';
import ExamProcessChart from './exam/process_chart';
import LrSkillSetResultChart from './lr_skill_set/result_chart';
import LrSkillSetProcessChart from './lr_skill_set/process_chart';
import ShowRating  from '../../_layouts/show_rating';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    gradesProcessTable    : '#js-grades-process-table',
    gradesRankingChart    : '.js-gpa-ranking-chart',
    genericResultChart    : '.js-generic-result-chart',
    genericProcessChart   : '.js-generic-process-chart',
    achieveResultChart    : '.js-achieve-result-chart',
    achieveProcessChart   : '.js-achieve-process-chart',
    mindResultChart       : '.js-mind-result-chart',
    mindProcessChart      : '.js-mind-process-chart',
    examResultChart       : '.js-exam-result-chart',
    examProcessChart      : '.js-exam-process-chart',
    lrSkillSetResultChart : '.js-lr-skill-set-result-chart',
    lrSkillSetProcessChart: '.js-lr-skill-set-process-chart',
    ratingFields          : '.js-rating-field'
  },

  onRender() {
    this.bindUIElements();
    this.renderGrades();
    this.renderGeneric();
    this.renderAchieve();
    this.renderMind();
    this.renderExam();
    this.renderLrSkillSet();
    this.renderRatings();
  },

  renderGrades() {
    if (this.ui.gradesProcessTable.length) {
      new GradesProcessTable({ el: this.ui.gradesProcessTable }).render();
    }
    _(this.ui.gradesRankingChart).each((element) => {
      new GradesRankingChart({ el: this.$(element) }).render();
    });
  },

  renderGeneric() {
    if (this.ui.genericResultChart.length) {
      new GenericResultChart({ el: this.ui.genericResultChart }).render();
    }
    if (this.ui.genericProcessChart.length) {
      new GenericProcessChart({ el: this.ui.genericProcessChart }).render();
    }
  },

  renderAchieve() {
    _(this.ui.achieveResultChart).each((element) => {
      new AchieveResultChart({ el: this.$(element) }).render();
    });
    _(this.ui.achieveProcessChart).each((element) => {
      new AchieveProcessChart({ el: this.$(element) }).render();
    });
  },

  renderMind() {
    _(this.ui.mindResultChart).each((element) => {
      new MindResultChart({ el: this.$(element) }).render();
    });
    _(this.ui.mindProcessChart).each((element) => {
      new MindProcessChart({ el: this.$(element) }).render();
    });
  },

  renderExam() {
    _(this.ui.examResultChart).each((element) => {
      new ExamResultChart({ el: this.$(element) }).render();
    });
    _(this.ui.examProcessChart).each((element) => {
      new ExamProcessChart({ el: this.$(element) }).render();
    });
  },

  renderLrSkillSet() {
    _(this.ui.lrSkillSetResultChart).each((element) => {
      new LrSkillSetResultChart({ el: this.$(element) }).render();
    });
    _(this.ui.lrSkillSetProcessChart).each((element) => {
      new LrSkillSetProcessChart({ el: this.$(element) }).render();
    });
  },

  renderRatings() {
    _(this.ui.ratingFields).each((element) => {
      new ShowRating({ el: this.$(element) }).render();
    });
  }
});
