import { View } from 'backbone.marionette';
import _ from 'underscore';
import jade from 'jade';
import Record from '../../../models/html';
import Chart from './chart';

export default View.extend({
  el      : '.asm-show-area',
  template: jade.compile('!= html'),

  model      : new Record(),
  modelEvents: {
    change: 'render',
    sync  : 'onSync'
  },

  ui: {
    mapbarTab     : '#js-mapbar a',
    mapbarTabFirst: '#js-mapbar a:first',
    scoreArea     : '.asm-achieve-score',
    levelArea     : '.asm-achieve-level'
  },

  onRender() {
    this.bindUIElements();
    this.ui.mapbarTab.on('shown.bs.tab', _.bind(this._loadChart, this));
  },

  // マップが変わる場合はシート数が変動するため再描画
  load(mapId, data) {
    if (!this.$el.data('url')) return;

    this._clearChart();
    this.data = data;
    this.$el.block();
    this.model.fetch({
      url: this.$el.data('url').replace('/:id.json', `/${mapId}.json`)
    });
  },

  // 検索条件だけ変わる場合はチャートのリロードで良い
  reload(data) {
    this.data = data;
    this._loadChart();
  },

  onSync() {
    this.$el.unblock();
    this.scoreChart = (new Chart({ el: this.ui.scoreArea }).render());
    this.levelChart = (new Chart({ el: this.ui.levelArea }).render());
    this.ui.mapbarTabFirst.tab('show');
  },

  // レーダーチャートはシート別に出力するのでシートIDを条件に追加する
  _loadChart() {
    const sheetId = this.$el.find('#js-mapbar li.active a').data('id');
    const data = _.extend({ sheet_id: sheetId }, this.data);
    if (this.scoreChart) this.scoreChart.load(data);
    if (this.levelChart) this.levelChart.load(data);
  },

  _clearChart() {
    if (this.scoreChart) this.scoreChart.destroy();
    if (this.levelChart) this.scoreChart.destroy();
  }
});
