import { View } from 'backbone.marionette';
import jade from 'jade';
import $ from 'jquery';
import Record from '../../../models/html';
import progressBarTemplate from '../../../templates/progress_bar.pug';
import stackBarTemplate    from '../../../templates/stack_bar.pug';

export default View.extend({
  el      : '.asm-achieve-rate',
  template: jade.compile('!= html'),

  ui: {
    avgBtn         : 'button.js-average',
    avgCell        : '.avg',
    avgProgressRate: 'td.avg.rate.js-progress',
    avgStackRate   : 'td.avg.rate.js-stack'
  },

  events: {
    'click @ui.avgBtn': 'onClickAverage'
  },

  model      : new Record(),
  modelEvents: {
    change: 'render',
    sync  : 'onSync'
  },

  load(mapId, data) {
    if (!this.$el.data('url')) return;

    this.$el.block();
    this.model.fetch({
      url : this.$el.data('url').replace('/:id/', `/${mapId}/`),
      data: data
    });
  },

  onSync() {
    this.$el.unblock();
  },

  onClickAverage() {
    this.$el.block();
    $.get(this.ui.avgBtn.data('url'))
      .done((data) => { this.renderAverage(data); })
      .always(() => { this.$el.unblock(); });
  },

  renderAverage(data) {
    this.ui.avgBtn.hide();
    this.ui.avgProgressRate.each((i, elem) => {
      $(elem).html(progressBarTemplate({
        value   : data.progress[i],
        barClass: 'progress-bar-warning'
      }));
    });
    this.ui.avgStackRate.each((i, elem) => {
      $(elem).html(stackBarTemplate({
        value   : data.stack[i][0],
        title   : data.stack[i][1],
        barClass: 'progress-bar-warning'
      }));
    });
    this.ui.avgCell.removeClass('hidden');
  }
});
