import cocktail   from './mixins/cocktail';
import colorIcons from './mixins/color_icons';
import DataTables from './mixins/datatables';
import SearchByGradeOrCurriculumYear from './mixins/search_by_grade_or_curriculum_year';

export default {
  cocktail,
  colorIcons,
  DataTables,
  SearchByGradeOrCurriculumYear
};
